// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import { fetchAuthSession } from 'aws-amplify/auth'
import { getLocalStorage } from '../hooks/appVariables'
import { getUserId } from '../utils/getUserId'
import useStore from '../store'

const gatewayApiURL = process.env.REACT_APP_GATEWAY_URI

const getIdToken = async () => {
  try {
    const { idToken } = (await fetchAuthSession()).tokens ?? {}
    return idToken
  } catch (err) {
    console.log("client.js getIdToken error: ", err)
  }
};

export async function client(endpoint, { body, ...customConfig } = {}) {
  const company = getLocalStorage(process.env.REACT_APP_COMPANY_KEY)
  const userId = getUserId()
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${await getIdToken()}`,
    'LoggedInAs': userId,
    'EntityId':useStore.getState().user.id,
    'CompanyId': company && company.id ? company.id : '1'
  }

  // If a method has been specified in the function parameters, use if. Otherwise, do a POST
  // if we have a body and a GET if we don't. (We may also do PUT and DELETE calls)
  const config = {
    method: (customConfig.method !== undefined && customConfig.method !== null) ? customConfig.method : body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  }

  if (body) {
    config.body = body
  }

  let data
  try {
    const response = await window.fetch(`${gatewayApiURL}${endpoint}`, config)
    try {
      data = await response.json()
    } catch {
      console.log('No call response')
    }
    if (response.ok) {
      // Return a result object similar to Axios
      return {
        status: response.status,
        data,
        headers: response.headers,
        url: response.url,
      }
    }
    throw new Error(response.statusText)
  } catch (err) {
    console.error(`The client fetch for ${gatewayApiURL}${endpoint} failed with: ${err.message}`)
    return Promise.reject(err.message ? err.message : data)
  }
}

client.get = function (endpoint, customConfig = {}) {
  return client(endpoint, { ...customConfig, method: 'GET' })
}

client.post = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body })
}

client.delete = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'DELETE'  })
}

client.put = function (endpoint, body, customConfig = {}) {
  return client(endpoint, { ...customConfig, body, method: 'PUT'  })
}
