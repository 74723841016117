import { useEffect, useState } from "react";
import { CheckboxField } from "@aws-amplify/ui-react";
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { getLocalStorage, setLocalStorage } from "../hooks/appVariables";

import Rocket from '../assets/images/marketing/green-and-blue-rocket-launching-vector-ultra-flat-.png';

const GreetingModal = () => {
    const showOnStart = getLocalStorage(process.env.REACT_APP_SHOW_STARTUP)

    const [modal, setModal] = useState(true)
    const toggle = () => setModal(!modal)

    useEffect(() => {
        if (showOnStart === "" || showOnStart === true) {
            setLocalStorage(process.env.REACT_APP_SHOW_STARTUP, true)
        } else {
            setModal(false)
        }
    }, [showOnStart])

    const handleDoNotShow = (e) => {
        setLocalStorage(process.env.REACT_APP_SHOW_STARTUP, false)
    }

    return (
        <Modal isOpen={modal} toggle={toggle} size="xl" centered={true}>
        <ModalHeader toggle={toggle}>Welcome to your all new Maintel Portal!</ModalHeader>
        <ModalBody>
            <Row>
                <Col className='display-8'>
                    <p>We're excited to unveil the newly updated Maintel portal, the culmination of months of dedicated development and innovation. </p>

                    <p>This refreshed platform embodies our commitment to delivering exceptional user experiences and cutting-edge technology. With enhanced features and a sleek, intuitive interface, the updated portal offers our clients even greater control and efficiency in managing their communication solutions. From streamlined navigation to powerful analytics tools, every aspect of the portal has been meticulously crafted to meet the evolving needs of our customers.</p>

                    <p>With the newly updated Maintel portal, we're empowering businesses to thrive in today's dynamic digital landscape with confidence and ease.</p>

                    <p>We hope you love it as much as we do!</p>
                </Col>
                <Col>
                    <img src={Rocket} alt="Rocket" style={{ width: '75%' }} className='img-fluid rounded float-end'/>
                </Col>
            </Row>

        </ModalBody>
        <ModalFooter style={{justifyContent: "space-between"}}>
            <span style={{display: "flex"}}>
                <CheckboxField id="chkDoNotShow" onClick={handleDoNotShow}></CheckboxField>&nbsp;&nbsp;
                <Label for='chkDoNotShow' style={{marginBottom: "0"}}>Don't show this message again</Label>
            </span>
            <Button className='no-print' color="primary" onClick={toggle}>
                OK
            </Button>
        </ModalFooter>
    </Modal>
    )
    
}
export default GreetingModal;